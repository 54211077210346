import React from "react";
import Button from "react-bootstrap/Button";
import { Card, Col, Container, Row } from "react-bootstrap";

const Activities: React.FC = () => {
  return (
    <>
      <h2 className="text-center" style={{ marginTop: "5rem" }}>
        Activities
      </h2>
      <div className="container my-5">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 g-1 mx-1 my-2">
          <div className="col">
            <div className="card shadow h-100 ">
              <img
                src="https://jpkushwaha.online//api/jpsir_img/Vasuli_mukti_divas.jpg"
                className="card-img-top h-50 "
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title text-center">
                  {" "}
                  समाजिक विपत्ति और समाजिक अन्याय (" Atrocities " )
                </h5>
                <p className="card-text text-center ">
                  जे पी सर् जी एक ऐसे समाज सेवक हैं जिनका समर्पण और समर्थन लोगों
                  के जीवन में बदलाव लाता है। उनकी दिल से आने वाली मदद और समर्थन
                  ने उन्हें लोगों के दिलों में गहरी पहचान दिलाई है। वे पैसे की
                  जरूरतमंद लोगों की मदद करते हैं और उन्हें आर्थिक सहायता प्रदान
                  करते हैं।
                </p>
                <p>
                  {" "}
                  जे पी सर् जी का योगदान समाज को एक मजबूत और संबलित महसूस कराता
                  है। उनकी सहायता से अनेक लोगों की जिंदगी में सकारात्मक परिवर्तन
                  आया है और उन्होंने अनेकों के लिए एक नई उम्मीद की किरण जगाई है।
                  उनका योगदान समाज की अस्थिरता को कम करने और उसे सहायक बनाने में
                  महत्वपूर्ण रोल निभाता है।
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card shadow h-100 ">
              <img
                src="https://jpkushwaha.online//api/jpsir_img/Free_spoken_english_class.jpg"
                className="card-img-top h-50 "
                alt="..."
              ></img>
              <div className="card-body">
                <h5 className="card-title text-center ">
                  {" "}
                  शिक्षा (" Education " )
                </h5>
                <p className="card-text text-center ">
                  आपने बहुत सही कहा है। जे पी सर् जी जैसे समाज सेवकों का सम्मान
                  होना चाहिए जो अपने समय और ऊर्जा को बच्चों के लिए नि:शुल्क
                  शिक्षा प्रदान करने में लगाते हैं। उनका कार्यक्रम उन बच्चों को
                  एक स्थान पर आकर्षित कर रहा है जहां उन्हें अच्छी शिक्षा के
                  साथ-साथ खेल और शैली में शिक्षा दी जा रही है। उनके प्रयासों से
                  न केवल बच्चे बल्कि समाज का भविष्य भी उजवल हो रहा है।
                  <p>
                    {" "}
                    जे पी सर् जी की इस पहल के बारे में और अधिक जानकारी के लिए,
                    उनकी विभिन्न कार्यक्रमों के बारे में बताने के लिए आप उनकी
                    वेबसाइट या सोशल मीडिया पेज पर जा सकते हैं। वहां पर आपको उनके
                    शिक्षा और पर्यावरण संरक्षण से जुड़े कार्यक्रमों के बारे में
                    अधिक जानकारी मिलेगी।
                  </p>
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card shadow h-100">
              <img
                src="https://jpkushwaha.online//api/jpsir_img/WhatsApp%20Image%202024-03-26%20at%2007.22.59_b0f46b3f.jpg"
                className="card-img-top h-50 "
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title text-centre ">
                  {" "}
                  जागरूकता या संवेदनशीलता (" Citizen awareness " )
                </h5>
                <p className="card-text text-center ">
                  जे पी सर् जी ने समाज में जागरूकता और संवेदनशीलता को बढ़ावा
                  देने के लिए कई नियम और उपाय अपनाए हैं। उन्होंने रूल्स और
                  कैम्प्स का आयोजन करके लोगों को जागरूक किया है और उन्हें समाज
                  सेवा के महत्व को समझाने की कोशिश की है। इससे लोगों में
                  संवेदनशीलता और सामाजिक जिम्मेदारी की भावना उत्पन्न होती है।
                </p>
                <p>
                  {" "}
                  जे पी सर् जी ने समाज में जागरूकता और संवेदनशीलता को बढ़ावा
                  देने के लिए कई नियम और उपाय अपनाए हैं। उन्होंने रूल्स और
                  कैम्प्स का आयोजन करके लोगों को जागरूक किया है और उन्हें समाज
                  सेवा के महत्व को समझाने की कोशिश की है। इससे लोगों में
                  संवेदनशीलता और सामाजिक जिम्मेदारी की भावना उत्पन्न होती है।
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card shadow h-100">
              <img
                src="https://jpkushwaha.online//api/jpsir_img/Chess_game.jpg"
                className="card-img-top h-75 "
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title text-center ">
                  {" "}
                  खेल (" Sports & Games "){" "}
                </h5>
                <p className="card-text text-center ">
                  जेपी सर की इस पहल से बच्चों को खेलने का मौका मिलता है जिससे
                  उनका शारीरिक और मानसिक विकास हो सकता है। खेल के माध्यम से
                  सिखाया जाता है कि जीवन में कैसे समस्याओं का सामना किया जाए और
                  उन्हें कैसे हल किया जाए। इससे उनकी पढ़ाई में भी सुधार हो सकता
                  है जिससे उन्हें जीवन में सफलता प्राप्त करने के लिए तैयारी हो।
                </p>
                <p>
                  यह बहुत ही दिल छू लेने वाली पहल है। जेपी सर ने बच्चों के लिए
                  वास्तव में अद्वितीय और संवेदनशीलता भावना से भरी पहलें की हैं।
                  उनकी इस पहल को समर्थन मिलना चाहिए और उनकी कार्यों का सम्मान
                  किया जाना चाहिए।.
                </p>
                <p>
                  {" "}
                  जेपी सर् ने बच्चों को खेलने का मौका देकर उनका शारीरिक और
                  मानसिक विकास किया है। खेल के माध्यम से सिखाया जाता है कि जीवन
                  में कैसे समस्याओं का सामना किया जाए और उन्हें कैसे हल किया
                  जाए। इससे उनकी पढ़ाई में भी सुधार हो सकता है जिससे उन्हें जीवन
                  में सफलता प्राप्त करने के लिए तैयारी हो।
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card shadow h-100 ">
              <img
                src="https://jpkushwaha.online//api/jpsir_img/tree_Plantation.jpg"
                className="card-img-top h-50"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title text-center ">पर्यावरण  ( "Environment  &  Tree plantation  " )</h5>
                <p className="card-text text-center">
                  जे पी सर् जी का पेड़ और पौधों के लिए पर्यावरण के प्रति योगदान
                  वास्तव में महत्वपूर्ण है। उन्होंने वृक्षारोपण और पौधों की
                  देखभाल को अपना उद्देश्य बनाया है ताकि पर्यावरण को बचाने में
                  मदद मिल सके। उनका यह कार्य वातावरण की सुरक्षा और संरक्षण में
                  महत्वपूर्ण योगदान है।{" "}
                </p>

                <p>
                  {" "}
                  जे पी सर् जी ने अनेक कार्यक्रम और अभियानों के माध्यम से पेड़
                  लगाने और पौधों की देखभाल को प्रोत्साहित किया है। उन्होंने
                  स्कूलों और कॉलेजों के छात्रों को शिक्षित करने के लिए योगदान
                  दिया है और साथ ही साथ उन्होंने विभिन्न समुदायों में पेड़ लगाने
                  के लिए जागरूकता फैलाने के लिए भी कई अभियान आयोजित किए हैं।{" "}
                </p>

                <p>
                  {" "}
                  उनके पेड़ लगाने और पौधों की देखभाल के कार्यक्रमों से न केवल
                  वातावरण को बल्कि समुदाय को भी एक मजबूत और स्वस्थ भविष्य की
                  दिशा मिल रही है।
                </p>

                <p>
                  {" "}
                  जे पी सर् जी के पेड़ लगाने और पौधों की देखभाल के कार्यक्रमों
                  से न केवल वातावरण को बल्कि समुदाय को भी एक मजबूत और स्वस्थ
                  भविष्य की दिशा मिल रही है।{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activities;
