import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { FaSquarePhone, FaSquareWhatsapp, FaSquareInstagram, FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { Spinner } from "react-bootstrap";


const About: React.FC = () => {

  const [adminData, setAdminData] = useState<any>({});
  const [loader, setloader] = useState<boolean>(false);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      setloader(true);
      const response = await fetch('https://jpkushwaha.online//api/profiledata.php');
      if (!response.ok) {
        throw new Error('Failed to fetch profile data');
      }
      const data = await response.json();
      setAdminData(data.admin[0]);
      setloader(false);
    } catch (error) {
      setloader(false);
      console.error('Error fetching profile data:', error);
    }
  };


  return (
    <>
      {
        loader ? (<div className='d-flex justify-content-center' style={{ alignItems: 'center', height: "100vh" }}>
          <Spinner animation="border" className='' role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>) : (<div>
          <h2 className="fs-3 col text-center" style={{ marginTop: '5rem' }}> About Us </h2 >
          <div className="container card shadow rounded p-5 mt-4 mb-3">
            <Container style={{}}>
              <Row>
                <Col sm={12} md={6}>
                  <div className="mt-4 text-center">
                    <Image
                      className="rounded-circle border border-5 img-fluid"
                      src={`https://jpkushwaha.online//api/media/${adminData.profile_img}`}
                      width={"300px"}
                      alt=""
                    />
                    <h4 className="my-2">JP Kushwaha</h4>
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="text-left  my-5">
                    <p>
                      जे.पी. कुशवाहा, "पूर्व प्रत्याशी विधानसभा क्षेत्र 75 वरिष्ठ
                      समाजसेवी रीवा, मध्य प्रदेश" के रूप में परिचित हैं। हमारा
                      उद्देश्य समाज की उन वर्गों की सेवा करना है जिनको समाज की
                      आवश्यकताएं सबसे अधिक हैं। हम समाज के विकास और कल्याण के
                      लिए प्रतिबद्ध हैं और न्याय, समानता, और सामाजिक ,समरसता को
                      बढ़ावा देने के लिए काम कर रहे हैं। हमारे संगठन का एक
                      महत्वपूर्ण ध्येय है समाज की सामाजिक, आर्थिक और सांस्कृतिक
                      स्थिति में सुधार करना। हम अपने क्षेत्र में विकास और प्रगति
                      के लिए कठिन परिश्रम कर रहे हैं और सभी समुदायों को समान
                      अवसर और सुरक्षा की पहुंच दिलाने के लिए प्रतिबद्ध हैं। हम
                      एक सामाजिक उत्थान और समृद्धि की दिशा में मिलकर काम करते हैं
                      ताकि हमारा समाज और हमारा क्षेत्र विकसित हो सकें।
                    </p>
                  </div>
                </Col>

              </Row>
              <h3 className="text-center mt-5 mx-5">Contact Us</h3>
              <div className=" text-center  mt-3 my-3">

                <a
                  href="tel:+918109251595"
                  className="phone-icon"

                >
                  <FaSquarePhone className="fs-1  mx-2 rounded-circle  " data-bs-toggle="tooltip"
                    data-bs-placement="right" data-bs-tooltip-color=""
                    title="+918109251595" style={{ color: "green" }} />
                </a>


                <a
                  href={adminData.fblink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookSquare
                    className="fs-1 mx-2  rounded-circle "
                    style={{ color: "#1877F2" }}
                  />
                </a>
                <a
                  href={adminData.instalink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaSquareInstagram
                    className="fs-1 mx-2  rounded-circle "
                    style={{ color: "#F56040" }}
                  />
                </a>
                <a
                  href="https://wa.me/+918109251595"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaSquareWhatsapp
                    className="fs-1 mx-2  rounded-circle"
                    style={{ color: "green" }}
                  />
                </a>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <FaSquareXTwitter
                    className="fs-1 mx-2  rounded-circle"
                    style={{ color: "black" }}
                  />
                </a>
              </div>
            </Container>
          </div>
        </div>)
      }
    </>
  );
};

export default About;
