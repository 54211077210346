import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Carousel, Pagination, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { FaCircleArrowLeft } from "react-icons/fa6";
const Home: React.FC = () => {
  const [blogs, setBlogs] = useState<any[]>([]);
  const [selectedBlog, setSelectedBlog] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [carouselInterval, setCarouselInterval] = useState<number | null>(5000);
  const blogsPerPage: number = 6;

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const headersList = {
          "Accept": "*/*",
          "User-Agent": "Thunder Client (https://www.thunderclient.com)"
        };

        const bodyContent = new FormData();
        bodyContent.append("userid", "1");

        const response = await fetch("https://jpkushwaha.online//api/getblogs.php", {
          method: "POST",
          body: bodyContent,
          headers: headersList
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const responseData = await response.json();
        setBlogs(responseData.blogs.reverse());
        setIsLoading(false); // Set isLoading to false when data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set isLoading to false even if there's an error
      }
    };

    fetchBlogs();
  }, []);

  const handleReadMoreClick = (blog: any) => {
    setSelectedBlog(blog);
  };

  const handleCloseSingleCard = () => {
    setSelectedBlog(null);
  };

  const formatPublishedDate = (dateString: string) => {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleDateString(); // Returns only the date portion
  };

  const renderDescriptionOrContent = (text: string) => {
    const slicedText = text.slice(0, 200); // Slice the text to first 200 characters

    if (isExpanded || text.length <= 200) { // Show full content if expanded or content length <= 200
      return (
        <div>
          {text}
          {!isExpanded && text.length > 200 && ( // Show "Read More" button if not expanded and content length > 200
            <Button variant="link" onClick={() => setIsExpanded(true)}>
              Read More
            </Button>
          )}
          {isExpanded && text.length > 200 && ( // Show "Read Less" button if expanded and content length > 200
            <Button variant="link" onClick={() => setIsExpanded(false)}>
              Read Less
            </Button>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {slicedText}...
          <Button variant="link" onClick={() => setIsExpanded(true)}>
            Read More
          </Button>
        </div>
      );
    }
  };

  const renderDesOrCont = (text: string) => {
    const slicedText = text.slice(0, 100); // Slice the text to first 200 characters
    return (
      <div>
        {slicedText}...
      </div>
    );
  };
  const renderTitle = (text: string) => {
    const slicedText = text.slice(0, 30); // Slice the text to first 200 characters
    return (
      <div>
        {slicedText}...
      </div>
    );
  };

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  function getYoutubeVideoId(url: string) {
    const regExp = /^.*(youtu\.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }

  return (
    <Container>
      <h1 className="mb-3 pb-3 text-center" style={{ marginTop: "75px" }}>Blogs</h1>

      {isLoading ? ( // Display loading message while data is being fetched
        <div className="text-center">Loading...</div>
      ) : (
        <>
          {selectedBlog ? (
            <div>
              <Card className='shadow mb-5 mt-3 container' key={selectedBlog.id}>
                <Card.Body>
                <FaCircleArrowLeft  className='m-2' onClick={handleCloseSingleCard} size={35} style={{ cursor: 'pointer' }}/>
                  <Card.Text> <strong>Published Date:</strong> {selectedBlog.date}</Card.Text>
                  <Card.Title>Title : {selectedBlog.title}</Card.Title>
                  <Carousel interval={carouselInterval} className="d-flex justify-content-center">
                    {selectedBlog.img1 &&
                      <Carousel.Item>
                        <img className="d-block w-100 img-responsive" src={`https://jpkushwaha.online//api/media/${selectedBlog.img1}`} alt="First slide" />
                      </Carousel.Item>
                    }
                    {selectedBlog.img2 &&
                      <Carousel.Item>
                        <img className="d-block w-100 img-responsive" src={`https://jpkushwaha.online//api/media/${selectedBlog.img2}`} alt="Second slide" />
                      </Carousel.Item>
                    }
                    {selectedBlog.img3 &&
                      <Carousel.Item>
                        <img className="d-block w-100 img-responsive" src={`https://jpkushwaha.online//api/media/${selectedBlog.img3}`} alt="Third slide" />
                      </Carousel.Item>
                    }
                    {selectedBlog.img4 &&
                      <Carousel.Item>
                        <img className="d-block w-100 img-responsive" src={`https://jpkushwaha.online//api/media/${selectedBlog.img4}`} alt="Fourth slide" />
                      </Carousel.Item>
                    }
                    {selectedBlog.video ? (
                      <Carousel.Item>
                        <div className="embed-responsive embed-responsive-16by9">
                          <iframe
                            className="embed-responsive-item d-block w-100 iframe-responsive"
                            src={`https://www.youtube.com/embed/${getYoutubeVideoId(selectedBlog.video)}`}
                            title="YouTube video player"
                            allowFullScreen
                            style={{
                              width: '100%', // Set width to 100%
                              height: '75vh', // Set height to auto to maintain aspect ratio
                            }}
                          ></iframe>
                        </div>
                      </Carousel.Item>
                    ) : null}
                  </Carousel>
                  <Card.Text>
                    <strong>Description:</strong>{renderDescriptionOrContent(selectedBlog.description)}
                  </Card.Text>
                  <Card.Text>
                    <strong> Content: </strong>   {parse(selectedBlog.content)}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          ) : (
            <>
              <Row className="mb-2">
                {currentBlogs.length > 0 ? (
                  currentBlogs.map((blog: any, index: number) => (
                    <Col key={index} xs={12} sm={6} md={4} lg={4} className="mb-3">
                      <Card className="shadow h-100" style={{ height: '200px' }}>

                        <Card.Body>
                        <Card.Title style={{ fontSize: 'larger' }}> Title :  {renderTitle(blog.title)}</Card.Title>
                          <p className="mb-0">Published Date: {formatPublishedDate(blog.date)}</p>
                          <img
                            className="img-thumbnail"
                          
                            src={`https://jpkushwaha.online//api/media/${blog.img1}`}
                            alt="Blog Image"
                          />
                          <Card.Text>{renderDesOrCont(blog.description)}</Card.Text>
                          <Button onClick={() => handleReadMoreClick(blog)} variant="primary">
                            Read More
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <div className="text-center">No more blogs to display.</div>
                )}
              </Row>

              <Pagination aria-label="Blog navigation" className="justify-content-center mt-0 pagination-sm">
                <Pagination.First onClick={() => paginate(1)}>First</Pagination.First>
                <Pagination.Prev onClick={() => paginate(currentPage - 1)}>&#9664;</Pagination.Prev>
                {Array.from({ length: Math.min(3, Math.ceil(blogs.length / blogsPerPage)) }).map((_, index) => (
                  <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => paginate(currentPage + 1)}>&#9654;</Pagination.Next>
                <Pagination.Last onClick={() => paginate(Math.ceil(blogs.length / blogsPerPage))}>Last</Pagination.Last>
              </Pagination>

            </>
          )}
        </>
      )}
    </Container>
  );
};

export default Home;
