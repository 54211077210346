import React from 'react'

const Contact:React.FC=()=>{
  return (
    <div>
      <h1>Contact</h1>
    </div>
  )
}

export default Contact
