import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

interface ImageData {
  id: string;
  Name: string;
}

const Gallery: React.FC = () => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    setLoader(true);
    fetch('https://jpkushwaha.online//api/getImages.php')
      .then(response => response.json())
      .then((data: { gallery: ImageData[] }) => {
        setImages(data.gallery);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <>
      {loader ? (
        <div className='d-flex justify-content-center' style={{ alignItems: 'center', height: "100vh" }}>
          <Spinner animation="border" className='' role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (<div>
        <h2 className="fs-2 col text-center" style={{ marginTop: '5rem' }}> Gallery </h2 >
        <div className="gallery">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 g-1 mx-1 my-2">
            {images.map(image => (
              <div key={image.id} className="col">
                <div className="card h-100 shadow-lg">
                  <Image
                    src={`https://jpkushwaha.online//api/jpsir_img/${image.Name}`}
                    fluid
                  />
                  {/* <img
                src={`https://jpkushwaha.online//api/jpsir_img/${image.Name}`}
                className="card-img-top"
                alt={image.Name}
                style={{ objectFit: 'fill', height: '400px' }}
              /> */}

                </div>
              </div>
            ))}
          </div>
        </div></div>)
      }
    </>
  );
}

export default Gallery;
